.no-js #loader {
  display: none;
}
.js #loader {
  display: block;
  position: absolute;
  left: 100px;
  top: 0;
}

.comp-results {
  margin-bottom: 5%;
  display: flex;
  flex-direction: column;
}

.comp-results > .comp-results-section {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 5px;
}

.composition-dashboard {
  padding-top: 1%;
  padding-bottom: 1%;
  background-color: #f4f4f4;
}

.composition-dashboard h5 {
  color: #1a667a;
}

.composition-dashboard strong {
  color: #1a667a;
}

.composition-dashboard .pie-center {
  margin: 0 auto;
}

.composition-dashboard li {
  list-style-type: none;
}

.composition-dashboard div.main-carousel {
  border: 2px;
  border-radius: 5%;
  margin: 0 auto;
  margin-bottom: 10px;
  padding-left: 5px;
  padding-right: 5px;
}

.composition-dashboard .trio-img {
  height: 100px;
  margin-top: 3%;
  margin-bottom: 3%;
}

.composition-dashboard ol.carousel-indicators li {
  background-color: #d9d9d9;
  height: 10px;
  width: 10px;
  border-radius: 50%;
}

.composition-dashboard .carousel-item.active {
  width: 100%;
}

.composition-dashboard .carousel-indicators .active {
  background-color: #1a667a;
}

.composition-dashboard .carousel-item {
  height: 340px;
}

.composition-dashboard .carousel-inner {
  padding-bottom: 50px;
}

.composition-dashboard a.carousel-control-next,
.composition-dashboard a.carousel-control-prev {
  background-color: #1a667a;
  height: 30px;
  width: 30px;
  margin-top: 280px;
  border-radius: 50%;
}

.composition-dashboard a.carousel-control-next {
  margin-right: 10px;
}

.composition-dashboard a.carousel-control-prev {
  margin-left: 10px;
}

.composition-dashboard .error-message {
  color: #de6351;
}
