.main-schedule-container {
  width: 90%;
  height: calc(100% - 100px);
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.schedule-selection-title {
  color: #3c3c3c;
  margin: 5px 20px 5px 20px;
}

.scheduling-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 12px;
}

.time-slots-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
}

.time-slots-container-title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  column-gap: 8px;
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 10px;
}

.time-slots-radio-buttons {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  overflow-y: auto;
  flex-wrap: nowrap;
}

.time-slots-radio-buttons label {
  gap: 0;
  margin-left: 1px;
  margin-right: 5px;
}

.bottom-button-container {
  background-color: white;
  width: 100%;
  padding: 10px 0 10px 0;
  text-align: center;
  box-shadow: 0 -3px 8px rgba(0, 0, 0, 0.1);
}

.appointment-btn {
  width: 315px;
  margin: 0 auto;
}

.booking-appointment-box {
  display: flex;
  flex-direction: column;
  border: 1px solid #dee5ec;
  border-radius: 4px;
  padding: 16px;
  background-color: white;
  box-shadow: 1px 3px 8px rgba(0, 0, 0, 0.1);
  gap: 5px;
  margin: 20px 0 20px 0;
  width: 80%;
}

.booking-datetime-container {
  display: flex;
  flex-direction: row;
  gap: 15px;
}

.booking-messages-container {
  gap: 10px;
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 0 auto;
  margin-top: 10px;
}

.booking-messages {
  color: #1e1e1e;
  text-align: center;
}

.booking-error-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.booking-error-message {
  text-align: center;
  padding: 0 20px 0 20px;
}

.date-calendar {
  width: 100% !important; /* I have to, sorry */
  margin: 0;
  padding: 0;
}
