/* Heart beat animation */
@keyframes beat {
  to {
    transform: scale(1.2);
  }
}

.toolbox-bottom-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  width: 100vw;
  background-color: black;
  justify-content: space-between;
  align-items: center;
}

.toolbox-control-inner-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 0.8rem;
  padding: 0.5rem;
}

.toolbox-control-inner-container svg {
  border-radius: 100%;
  color: white;
  cursor: pointer;
  font-size: 14px;
  fill: white;
  background-color: transparent;
}

.toolbox-control-inner-container svg:hover {
  opacity: 0.8;
}

.toolbox-control-inner-container:hover {
  opacity: 1;
}

.toolbox-btn-icon-container {
  background-color: #555b72;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  cursor: pointer;
}

.toolbox-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 5px;
  min-height: 40px;
}

.toolbox-button > label {
  color: white;
  font-size: 14px;
  font-weight: 500;
  white-space: pre-wrap;
  text-align: center;
  margin-top: 5px;
}

.toolbox-button-red {
  background-color: #d56250;
  fill: #d56250;
  color: white;
}

.toolbox-button-green {
  background-color: #1a667a;
  fill: #1a667a;
  color: white;
}

.toolbox-inner-container-fixed {
  width: 160px;
}

.tool-box-toast-center {
  bottom: 100px;
  width: 500px;
  position: absolute;
  text-align: left;
}

.tool-box-toast-right {
  bottom: 100px;
  right: 2px;
  width: 280px;
  position: absolute;
  text-align: left;
}

.recording-dialog {
  font-size: 1rem;
  position: fixed;
  top: 1rem;
  left: 1rem;
  padding: 0.5rem;
  border: 1px dashed #1a667a;
  background-color: white;
  color: black;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}

.recording-dialog svg {
  fill: #000;
}

.recording-dialog.recording svg {
  fill: #d56250;
  transform-origin: center;
  animation: beat 0.5s infinite alternate;
}

.toolbox-button .recording-idle {
  transform-origin: center;
  animation: beat 0.5s infinite alternate;
}

.toolbox-bottom-bar .toolbox-action-dummy {
  width: 60px;
}

.toolbox-bottom-bar .__react_component_tooltip.show {
  height: 20px;
}

@media only screen and (max-width: 600px) {
  .toolbox-bottom-bar {
    justify-content: center;
    align-items: center;
    background-color: transparent;
    width: 100%;
    border-radius: 0;
    flex-wrap: wrap;
    padding: 0;
    background-color: black;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .toolbox-control-inner-container {
    gap: 0;
    align-self: center;
  }

  .tool-box-btn-icon-container {
    width: 50px;
    height: 50px;
  }

  .toolbox-button {
    margin: 10px;
  }

  .toolbox-inner-container-fixed {
    width: 0;
  }

  .toolbox-bottom-bar .toolbox-action-dummy {
    width: 0px;
  }
}
