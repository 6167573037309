/* Video call app container */
.app-chime {
  background-size: cover;
  box-sizing: border-box;
  font-size: 1em;
  font-weight: bold;
  overflow-x: hidden;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

/* Main container for the video call */
.chime-video-call-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100dvh;
  width: 100vw;
  background-color: #dcf0ef;
}

/* Container for screen sharing */
.app-chime--screen-share .chime-video-call-container__screen-share {
  width: 70vw;
  max-width: 100vw;
  height: 65vh;
  margin-bottom: 20px;
  margin-top: 5px;
  border-radius: 15px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e3e3e3;
  position: relative;
}

.app-chime--screen-share .chime-video-call-container__screen-share video {
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: unset;
  left: unset;
  top: unset;
  transform: rotate(0deg);
}

.app-chime--screen-share .chime-video-call-container__video-track--content {
  width: 50vw;
  height: 60vh;
  border-radius: 15px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e3e3e3;
  position: relative;
}

.app-chime--screen-share
  .chime-video-call-container__participant-videos
  .chime-video-call-container__video-track {
  width: 15vw;
  height: 20vh;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e3e3e3;
  margin: 0 1vw;
  flex-shrink: 0;
}

/* Container for participant videos */
.chime-video-call-container__participant-videos {
  margin: 0;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
}

/* Style for each video track */
.chime-video-call-container__video-track {
  width: 250px;
  height: 150px;
  border-radius: 15px;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e3e3e3;
  margin: 0 5px;
  flex-shrink: 0;
}

/* Style specifically for local video track */
.chime-video-call-container__video-track--local {
  width: 200px;
  height: 150px;
  border-radius: 15px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e3e3e3;
  margin: 0 5px;
}

/* Style for screen share video */
.chime-video-call-container__video-track--content {
  width: 100%;
  height: 100%;
  max-height: 100%;
}

/* Styles for participant names below the video */
.chime-video-call-container__participant-name {
  position: absolute;
  bottom: 5px;
  left: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.85em;
  max-width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  z-index: 2;
}

/* Styles for the control buttons */
.chime-video-call-container__control-buttons {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  z-index: 1000;
}

/* Container for participants in non-screen share mode */
.chime-video-call-container__participants {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  height: 85vh;
  width: 100%;
  min-height: 500px;
  min-width: 1000px;
}

.chime-video-call-container__waiting {
  justify-content: center;
  justify-self: center;
  margin-top: auto;
}

/* Remote video in non-screen share mode */
.chime-video-call-container__remote-video {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #dcf0ef;
  border-radius: 15px;
  margin-bottom: 10px;
  overflow: hidden;
}

/* Local video in non-screen share mode */
.chime-video-call-container__local-videos {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: auto;
  height: auto;
}

/* Style for remote video track */
.chime-video-call-container__video-track--remote {
  margin-top: 40px;
  object-fit: cover;
  width: 500px;
  height: calc(100% - 40px);
  /*max-width: 60vw;
  max-height: 700px;*/
}

.chime-video-call-container__video-track--remote .chime-video-call-container__video {
  margin: auto;
  top: 0;
  left: 0;

  object-fit: cover;
  width: 500px;
  height: calc(100%);
  /*max-width: 60vw;
  max-height: 700px;*/
}

.chime-video-call-container__no-video-label {
  color: #000;
  font-size: 1.5em;
  font-weight: bold;
  margin-top: 20px;
}

@media only screen and (max-width: 600px) {
  .chime-video-call-container {
    height: 100dvh;
  }

  .chime-video-call-container__participants {
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
  }

  .chime-video-call-container__remote-video {
    width: 100%;
    height: 100%;
    max-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    border-radius: 0;
  }

  .chime-video-call-container__video-track {
    border-radius: 10px;
    border: 1px solid white;
    background-color: #e3e3e3;
    box-shadow: 3px 3px 3px rgba(177, 177, 177, 0.5);
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
  }

  .chime-video-call-container__video-track--remote {
    width: 100%;
    height: auto;
  }

  .chime-video-call-container__video {
    object-fit: cover;
    height: 100%;
    width: 100%;
    margin: auto;
    top: 0;
    left: 0;
  }

  .chime-video-call-container__local-videos {
    position: fixed;
    height: 20%;
    width: 30%;
    right: 0.5em;
    top: 5px;
  }

  .chime-video-call-container__video-track--local {
    height: 100%;
    width: 100%;
  }

  .chime-video-call-container__participant-name {
    font-size: 0.5em;
    align-self: center;
    left: auto;
  }

  .chime-video-call-container__no-video-label {
    margin-top: 60%;
  }

  .app-chime--screen-share .chime-video-call-container__screen-share {
    width: 100vw;
    height: 65vh;
    margin: 0;
    border-radius: 0;
  }

  .app-chime--screen-share
    .chime-video-call-container__participant-videos
    .chime-video-call-container__video-track {
    width: 100%;
    flex: 1;
  }

  .app-chime--screen-share .chime-video-call-container__video-track--content {
    width: 100%;
    height: 100%;
  }

  .chime-video-call-container__waiting {
    margin-top: 0;
  }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .chime-video-call-container__screen-share {
    height: 40vh;
  }
}
