.error-boundary-container {
  height: inherit;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
}

.error-message {
  text-wrap: balance;
  text-align: center;
}
