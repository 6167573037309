.prompt-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: space-evenly;
  padding: 10px;
  text-align: center;
}

.prompt-container > svg {
  width: 200px;
  height: 80px;
}
