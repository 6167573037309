.noShowUpperBox {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  margin-bottom: 40px;
}

.noShowLowerBox {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  margin-top: 30px;
  font-size: 20px;
  font-weight: lighter;
}
