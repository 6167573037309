.app {
  background-size: cover;
  bottom: 0;
  box-sizing: border-box;
  font-size: 1em;
  font-weight: bold;
  left: 0;
  overflow: scroll;
  position: fixed;
  top: 0;
  width: 100%;
}

.header {
  align-items: center;
  background-color: rgb(255, 255, 255);
  border-bottom: 2px solid rgb(168, 217, 214);
  display: flex;
  justify-content: center;
  padding-bottom: 16px;
  padding-top: 16px;
  width: 100%;
}

.header img {
  height: 40px;
  width: 40px;
}

.body {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 76%;
}

.title {
  color: rgb(26, 102, 122);
  margin: 1.8em 0px;
  position: relative;
  text-align: center;
}

.subtitle {
  color: #1a667a;
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-bottom: 1em;
  width: 100%;
}

.room {
  align-items: center;
  background-color: #eef7f7;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.participants {
  display: flex;
  height: 100%;
  position: relative;
  width: 100%;
}

.localParticipant {
  font-size: 0.8em;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 20%;
  z-index: 100;
}

.remoteParticipants {
  display: flex;
  font-size: 1.6em;
  width: 100%;
  height: 100%;
}

.participant {
  height: 100%;
  width: 100%;
}

.waitMessage {
  align-items: center;
  color: #1a667a;
  display: flex;
  justify-content: center;
  width: 100%;
}

.waitMessage div {
  text-align: center;
}

.waitMessage div span {
  font-size: 1.1em;
  font-weight: bolder;
}

.identity {
  box-sizing: border-box;
  color: white;
  padding-left: 15px;
  position: absolute;
  text-align: center;
  top: 5%;
  width: 100%;
  z-index: 50;
}

video {
  display: block;
  left: -2px;
  position: relative;
  top: -2px;
  transform: scale(-1, 1);
  width: 100%;
  -webkit-transform: scale(-1, 1);
}

.toggle {
  background-color: #60667f;
}

.toggle.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.remoteParticipants .video {
  display: flex;
  justify-content: center;
}

.remoteParticipants .video,
.remoteParticipants video {
  height: 100%;
  transform: none;
  width: auto;
  -webkit-transform: none;
}

.localParticipant video {
  border-radius: 10px;
  border: solid white;
}

.noMirror video {
  transform: none;
  -webkit-transform: none;
}

button {
  background-color: #1a667a;
  border-radius: 5px;
  border: none;
  color: white;
  cursor: pointer;
  display: block;
  font-weight: bold;
  margin: 0 auto;
  padding: 0.8em;
  width: auto;
}

.buttonContent {
  align-items: center;
  display: flex;
  justify-content: center;
}

.buttonContent img {
  margin-right: 10px;
}

.iconWrapper {
  display: inline-flex;
}

button:disabled {
  opacity: 0.4;
  cursor: initial;
}

input {
  background-color: rgba(255, 255, 255, 0);
  border-bottom: 2px solid #1a667a;
  border-radius: 0;
  border: none;
  font-size: 1em;
  margin-bottom: 25px;
  outline: none;
  padding-bottom: 5px;
  text-align: center;
  width: 300px;
}

@media (max-width: 1023px) {
  @media (orientation: portrait) {
    .localParticipant {
      font-size: 0.5em;
      right: 20px;
      top: 10%;
      width: 37%;
    }

    .remoteParticipants {
      font-size: 1em;
    }

    .remoteParticipants .identity {
      top: 1.5%;
    }

    .remoteParticipants .video,
    .remoteParticipants video {
      height: 100%;
      width: 100%;
    }

    .participant {
      height: auto;
    }
  }

  @media (orientation: landscape) {
    .header {
      padding-bottom: 10px;
      padding-top: 10px;
    }

    .localParticipant {
      font-size: 0.5em;
      right: 10px;
      top: 10px;
      width: 25%;
    }

    .remoteParticipants {
      font-size: 1em;
    }

    .remoteParticipants .identity {
      top: 1.5%;
    }

    .wrapper {
      width: 90%;
    }

    .title {
      margin: 0.6em 0px;
    }
  }
}

@media (max-width: 568px) {
  @media (orientation: landscape) {
    .app {
      font-size: 0.9em;
    }

    .header {
      padding-bottom: 10px;
      padding-top: 10px;
    }

    .header img {
      height: 28px;
      width: 28px;
    }
  }
}

@media (max-width: 375px) {
  @media (orientation: portrait) {
    .app {
      font-size: 0.9em;
    }

    .header {
      padding-bottom: 10px;
      padding-top: 10px;
    }

    .header img {
      height: 28px;
      width: 28px;
    }

    .title {
      margin: 1em 0px;
    }
  }
}
