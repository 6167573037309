.error-container {
  display: flex;
}

.error-container .error-text {
  margin: auto;
}

.error-container .text-center {
  text-align: center;
}

.error-container .error-message {
  color: #de6351;
}
