.group-main-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
}

.group-class-header {
  height: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.group-class-header > img {
  height: 100%;
  max-height: 300px;
  object-fit: cover;
}

.group-content-container {
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.group-class-title {
  width: 100%;
  align-self: flex-start;
}

.group-class-tag-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  overflow-y: hidden;
}

.group-class-pill {
  display: flex;
  flex-direction: column;
  height: 18px;
  border-radius: 10px;
  border: 1px solid #1a667a;
  padding: 5px;
  font-size: 12px;
  align-items: center;
  margin: 3px;
  flex-wrap: wrap;
}

.group-class-tag {
  color: #1a667a;
}

.group-class-description {
  overflow-x: hidden;
  text-align: justify;
  font-size: 18px;
  font-weight: 400;
  color: #404040;
}

.group-class-btn {
  display: flex;
  flex-direction: column;
}

.group-class-btn-provider-name {
  font-size: 10px;
}

.group-main-container .fab-action {
  position: absolute;
  top: 2em;
  right: 1em;
}

.group-main-container .fab-action svg {
  font-size: 15px;
}
