.group-class-confirmation-page {
  align-items: center;
  padding: 1em;
}

.group-class-confirmation-page > .main-content {
  width: 100%;
  max-width: 350px;
}

.group-class-confirmation-page .title {
  color: #278ba5;
  text-align: start;
}

.group-class-confirmation-page .content {
  gap: 20px;
}

.group-class-confirmation-page .appointment-type-host p {
  font-size: 18px;
}

.group-class-confirmation-page .appointment-type-details {
  gap: 0.2em;
}

.group-class-confirmation-page .appointment-type-details p {
  font-size: 18px;
}

.group-class-confirmation-page .appointment-type-details > .item {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.7em;
}

.group-class-confirmation-page .appointment-type-details svg {
  font-size: 20px;
}

.group-class-confirmation-page .actions {
  padding: 1em;
  text-align: center;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.group-class-confirmation-page .actions button {
  width: 100%;
  max-width: 350px;
  height: 47px;
}

.group-class-confirmation-page .fab-action {
  position: absolute;
  top: 2em;
  right: 1em;
}

.group-class-confirmation-page .fab-action svg {
  font-size: 15px;
}
