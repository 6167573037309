.successful-registration-page {
  align-items: center;
  padding: 1em;
}

.successful-registration-page > .main-content {
  width: 100%;
  max-width: 350px;
  gap: 20px;
  text-align: center;
  font-size: 20px;
}

.successful-registration-page .actions {
  padding: 1em;
  text-align: center;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.successful-registration-page .actions button {
  width: 100%;
  max-width: 350px;
  height: 47px;
}

.successful-registration-page .fab-action {
  position: absolute;
  top: 2em;
  right: 1em;
}

.successful-registration-page .fab-action svg {
  font-size: 15px;
}
