.core-container {
  height: 100vh;
  width: 100%;
  max-width: 600px;
  margin: auto;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  align-items: center;
  background-color: white;
}

.core-container > svg {
  margin-top: 20px;
  margin-bottom: 20px;
}
