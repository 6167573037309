.recording-dialog-container .MuiDrawer-paper {
  padding: 0.5em;
}

.recording-dialog-container .MuiDrawer-paper .recording-dialog-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  justify-items: center;
}

.recording-dialog-container .MuiDrawer-paper .recording-dialog-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.5em;
  height: calc(100vh - 200px);
}

.recording-dialog-container .MuiDrawer-paper .recording-dialog-content .dialog-form span {
  font-size: 1.125rem;
}

.recording-dialog-container .MuiDrawer-paper .recording-dialog-content .dialog-form button {
  display: flex;
  flex-direction: row;
  width: auto;
  margin: auto;
  margin-top: 1rem;
  font-size: 1.125rem;
}
