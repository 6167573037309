.gc-container {
  overflow-y: auto;
}

.gc-container * {
  font-family: 'Proxima Nova' !important;
}

.gc-card-container {
  align-items: center;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 0 auto;
  margin-top: 10px;
  margin-bottom: 10px;
  max-width: 1500px;
  width: calc(100% - 10%);
}

.gc-cover {
  width: 100%;
  max-height: 116px;
  border-radius: 8px 8px 0px 0px;
  object-fit: cover;
}

.gc-details {
  display: flex;
  width: 90%;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 15px;
  gap: 16px;
}

.gc-title {
  inline-size: 100%;
  font-size: 20px;
  overflow-wrap: break-word;
  color: #404040;

  font-family: 'Proxima Nova' !important;
  font-style: normal;

  line-height: 26px !important;
  font-weight: 700 !important;
}

.gc-register-container {
  border: 1px solid #e7e7e7;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 5px;
  gap: 8px;
}

.gc-date {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4px;
  width: 48px;
  height: 48px;
  box-sizing: border-box;
  background: #ffe24c;
  border-radius: 5px;

  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
}
.gc-date .gc-date-day {
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 700;
  font-size: 26px;

  color: #404040;
}

.gc-appointment-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content: center;
  align-items: flex-start;

  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;

  gap: 7px;
}

.gc-register-btn {
  height: 48px;
  min-width: 92px !important;
  font-size: 16px !important;
  align-self: center;
  background-color: #278ba5 !important;
  text-transform: none !important;
  border-radius: 5px !important;
}

.gc-appointment-row {
  display: flex;
  flex-direction: row;
}

.gc-appointment-text {
  font-size: 14px;
  font-weight: 400;
  line-height: 11px;
  font-family: 'Proxima Nova';
  color: #404040;
  padding: 2px;
}

.gc-class-info-and-dates-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.gc-class-info-and-dates {
  color: #278ba5;
}
